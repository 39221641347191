.form {
  display: flex;
  flex-direction: column;
  margin-top: 20px;
  width: 100%;
  height: 330px;

  input, textarea {
    padding: 5px 10px;
    resize: vertical;
    max-height: 300px;
    border: 1px solid var(--border);
    border-radius: 5px;
    margin-bottom: 10px;
  }
  .buttonSmall {
    align-self: center;
    margin-top: auto;
    border-radius: 5px;
    background: linear-gradient(to right, #67b26b, #4ca2cb);
    border: none;
    color: var(--light);
    text-align: center;
    text-transform: uppercase;
    font-size: 10px;
    padding: 10px;
    padding-top: 5px;
    transition: all 0.4s;
    cursor: pointer;
    width: 100%;
    &:hover {
      background: linear-gradient(to right, #80c684, #6db9dd);
    }
  }
  .buttonLarge {
    margin-top: auto;
    border-radius: 5px;
    background: linear-gradient(to right, #67b26b, #4ca2cb);
    border: none;
    color: var(--light);
    text-align: center;
    text-transform: uppercase;
    font-size: 22px;
    padding: 20px;
    transition: all 0.4s;
    cursor: pointer;
    width: 100%;
    &:hover {
      background: linear-gradient(to right, #80c684, #6db9dd);
    }
  }
}


