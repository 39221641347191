.image {
  width: 100%;
  height: 100px;
  object-fit: cover;
  border-radius: 10px 10px 0 0;
}
.header {
  position: absolute;
  top:  50px;
  left: 20px;
  font-size: 30px;
  font-weight: bold;
  color: var(--light);
}